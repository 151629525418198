import styled from 'styled-components';

export const Section = styled.div`
    margin-top: 200px;

    @media (max-width: 800px) {
        margin-top: 150px;
    }
`;

export const MobileBreak = styled.br`
    @media (min-width: 601px) {
        display: none;
    }
`;

export const DesktopBreak = styled.br`
    @media (max-width: 800px) {
        display: none;
    }
`;
