import { CustomIconProps } from '@aleohq/components/dist/components/Icons/types';
import Icon from '@ant-design/icons';

const Step1Svg = () => (
    <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="25.5" cy="25.5" r="25" fill="#12141D" stroke="url(#paint0_linear)" />
        <path
            d="M25.5012 37.2684C31.8057 37.2684 36.9165 32.0002 36.9165 25.5015C36.9165 19.0029 31.8057 13.7346 25.5012 13.7346C19.1967 13.7346 14.0859 19.0029 14.0859 25.5015C14.0859 32.0002 19.1967 37.2684 25.5012 37.2684Z"
            stroke="url(#paint1_linear)"
            stroke-width="0.54"
            stroke-miterlimit="10"
        />
        <path
            d="M25.5001 43C34.6128 43 42.0002 39.7244 42.0002 35.6837C42.0002 31.643 34.6128 28.3673 25.5001 28.3673C16.3873 28.3673 9 31.643 9 35.6837C9 39.7244 16.3873 43 25.5001 43Z"
            stroke="url(#paint2_linear)"
            stroke-width="0.54"
            stroke-miterlimit="10"
        />
        <path
            d="M25.5001 22.6327C34.6128 22.6327 42.0002 19.3571 42.0002 15.3164C42.0002 11.2757 34.6128 8 25.5001 8C16.3873 8 9 11.2757 9 15.3164C9 19.3571 16.3873 22.6327 25.5001 22.6327Z"
            stroke="url(#paint3_linear)"
            stroke-width="0.54"
            stroke-miterlimit="10"
        />
        <defs>
            <linearGradient
                id="paint0_linear"
                x1="0.430801"
                y1="39.2611"
                x2="51.3772"
                y2="38.7149"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#0BACA2" />
                <stop offset="1" stop-color="#6BEA65" />
            </linearGradient>
            <linearGradient
                id="paint1_linear"
                x1="15.092"
                y1="36.9082"
                x2="37.8921"
                y2="34.9872"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#18E48F" />
                <stop offset="1" stop-color="#6BEA65" />
            </linearGradient>
            <linearGradient
                id="paint2_linear"
                x1="10.4542"
                y1="42.7761"
                x2="42.418"
                y2="36.5154"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#18E48F" />
                <stop offset="1" stop-color="#6BEA65" />
            </linearGradient>
            <linearGradient
                id="paint3_linear"
                x1="10.4542"
                y1="22.4088"
                x2="42.418"
                y2="16.1481"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#18E48F" />
                <stop offset="1" stop-color="#6BEA65" />
            </linearGradient>
        </defs>
    </svg>
);

export const Step1Icon = (props: CustomIconProps) => <Icon component={Step1Svg} {...props} />;
