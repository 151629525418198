import { CustomIconProps } from '@aleohq/components/dist/components/Icons/types';
import Icon from '@ant-design/icons';

const TwitterSvg = () => (
    <svg width="33" height="30" viewBox="0 0 33 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M33 3.32605C31.7864 3.8728 30.4658 4.26629 29.1055 4.41954C30.5178 3.54324 31.5754 2.15883 32.0799 0.526052C30.7547 1.34944 29.3028 1.92681 27.7888 2.23256C27.1561 1.52604 26.3908 0.963199 25.5406 0.579101C24.6904 0.195002 23.7737 -0.00211981 22.8474 1.7191e-05C19.0996 1.7191e-05 16.0856 3.1728 16.0856 7.06628C16.0856 7.61303 16.149 8.15978 16.2521 8.68581C10.6404 8.3793 5.6355 5.5793 2.30814 1.29232C1.70185 2.37387 1.38414 3.60533 1.38805 4.85859C1.38805 7.31066 2.58178 9.47279 4.40211 10.7444C3.32936 10.7003 2.28173 10.3923 1.34443 9.84557V9.93255C1.34443 13.3663 3.66843 16.2118 6.76577 16.8663C6.18421 17.024 5.58596 17.1048 4.9851 17.1065C4.54489 17.1065 4.12847 17.061 3.70809 16.9988C4.56472 19.7988 7.05925 21.8325 10.0297 21.8988C7.70568 23.8 4.79474 24.9183 1.63394 24.9183C1.06682 24.9183 0.543324 24.8976 0 24.8314C2.9982 26.8402 6.55558 28 10.3866 28C22.8236 28 29.629 17.2391 29.629 7.89883C29.629 7.59232 29.629 7.28581 29.6092 6.9793C30.9258 5.97279 32.0799 4.72605 33 3.32605Z"
            fill="white"
        />
    </svg>
);

export const TwitterIcon = (props: CustomIconProps) => <Icon component={TwitterSvg} {...props} />;
