import { CustomIconProps } from '@aleohq/components/dist/components/Icons/types';
import Icon from '@ant-design/icons';

const Step2Svg = () => (
    <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="25.5" cy="25.5" r="25" fill="#12141D" stroke="url(#paint0_linear)" />
        <path
            d="M25.505 21.6844C34.5513 21.6844 41.8847 18.6211 41.8847 14.8422C41.8847 11.0634 34.5513 8 25.505 8C16.4588 8 9.12536 11.0634 9.12536 14.8422C9.12536 18.6211 16.4588 21.6844 25.505 21.6844Z"
            stroke="url(#paint1_linear)"
            stroke-width="0.54"
            stroke-miterlimit="10"
        />
        <path
            d="M25.5047 32.3407C34.5509 32.3407 41.8844 29.2773 41.8844 25.4985C41.8844 21.7196 34.5509 18.6562 25.5047 18.6562C16.4584 18.6562 9.125 21.7196 9.125 25.4985C9.125 29.2773 16.4584 32.3407 25.5047 32.3407Z"
            stroke="url(#paint2_linear)"
            stroke-width="0.54"
            stroke-miterlimit="10"
        />
        <path
            d="M25.5047 43C34.5509 43 41.8844 39.9366 41.8844 36.1578C41.8844 32.3789 34.5509 29.3156 25.5047 29.3156C16.4584 29.3156 9.125 32.3789 9.125 36.1578C9.125 39.9366 16.4584 43 25.5047 43Z"
            stroke="url(#paint3_linear)"
            stroke-width="0.54"
            stroke-miterlimit="10"
        />
        <path
            d="M7.77111 25.516C6.75188 25.1945 5.85044 24.5722 5.18205 23.7286C4.51365 22.885 4.1087 21.8584 4.01899 20.7803C3.92927 19.7022 4.15886 18.6215 4.67841 17.6763C5.19795 16.7312 5.98383 15.9645 6.93553 15.4745"
            stroke="url(#paint4_linear)"
            stroke-width="0.54"
            stroke-miterlimit="10"
        />
        <path
            d="M43.2344 35.5572C44.2533 35.2347 45.1542 34.6117 45.822 33.7677C46.4898 32.9237 46.8943 31.8971 46.9836 30.819C47.073 29.7409 46.8433 28.6602 46.3238 27.7151C45.8043 26.7699 45.0186 26.0033 44.0671 25.5129"
            stroke="url(#paint5_linear)"
            stroke-width="0.54"
            stroke-miterlimit="10"
        />
        <defs>
            <linearGradient
                id="paint0_linear"
                x1="0.430801"
                y1="39.2611"
                x2="51.3772"
                y2="38.7149"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#0BACA2" />
                <stop offset="1" stop-color="#6BEA65" />
            </linearGradient>
            <linearGradient
                id="paint1_linear"
                x1="10.5689"
                y1="21.475"
                x2="42.1516"
                y2="14.9085"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#18E48F" />
                <stop offset="1" stop-color="#6BEA65" />
            </linearGradient>
            <linearGradient
                id="paint2_linear"
                x1="10.5686"
                y1="32.1312"
                x2="42.1513"
                y2="25.5647"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#18E48F" />
                <stop offset="1" stop-color="#6BEA65" />
            </linearGradient>
            <linearGradient
                id="paint3_linear"
                x1="10.5686"
                y1="42.7905"
                x2="42.1513"
                y2="36.224"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#18E48F" />
                <stop offset="1" stop-color="#6BEA65" />
            </linearGradient>
            <linearGradient
                id="paint4_linear"
                x1="4.16618"
                y1="25.3623"
                x2="7.95496"
                y2="25.2388"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#18E48F" />
                <stop offset="1" stop-color="#6BEA65" />
            </linearGradient>
            <linearGradient
                id="paint5_linear"
                x1="43.4004"
                y1="35.4035"
                x2="47.1862"
                y2="35.2802"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#18E48F" />
                <stop offset="1" stop-color="#6BEA65" />
            </linearGradient>
        </defs>
    </svg>
);

export const Step2Icon = (props: CustomIconProps) => <Icon component={Step2Svg} {...props} />;
