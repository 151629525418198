import React from 'react';
import { Col, Layout, Row } from 'antd';
import { AleoBanner, AleoHeader, AleoIcon, AleoMiniFooter, AleoTypography } from '@aleohq/components';
import logo from './assets/logo.png';
import styled from 'styled-components';
import Title from './components/Title';
import { MobileBreak, Section } from './components/common';
import keyGraphic from './assets/key-graphic.png';
import RollupSteps from './components/RollupSteps';
import ZeroReExecution from './components/ZeroReExecution';
import CryptographicIntegrity from './components/CryptographicIntegrity';
import UnlimitedRuntime from './components/UnlimitedRuntime';
import Newsletter from './components/Newsletter';
import Social from './components/Social';
import RollupInfo from './components/RollupInfo';

const Logo = styled.div`
    img {
        height: 30px;
    }
`;

const RollupBanner = styled(AleoBanner)`
    display: flex;
    align-items: center;

    @media (max-width: 600px) {
        height: 84px;
        justify-content: space-between;
        align-items: flex-end;
        padding: 10px 15px;
    }
`;

const BlackText = styled.span`
    color: #000000;

    @media (max-width: 600px) {
        display: block;
        max-width: 250px;
    }
`;

const Underline = styled.span`
    border-bottom: 1px solid #ffffff;
    padding-bottom: 2px;
`;

const BannerButton = styled.a`
    display: flex;
    align-items: center;
    margin-left: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: #ffffff !important;

    &:hover {
        color: #ffffff !important;
    }
`;

const KeyGraphic = styled.div`
    height: 250px;
    width: 250px;
    margin: auto;
    background: url(${keyGraphic});
    background-size: contain;
`;

const IconWrapper = styled.div`
    display: inline-block;
    position: relative;
    margin-right: 3px;
`;

const RollupDesc = styled(AleoTypography)`
    @media (max-width: 800px) and (min-width: 600px) {
        font-size: 24px !important;
    }
`;

const App = () => {
    return (
        <Layout>
            <AleoHeader
                logo={logo}
                logoRender={(logo) => <Logo>{logo}</Logo>}
                banner={
                    <RollupBanner background="linear-gradient(89.44deg, #0BACA2 0.48%, #6BEA65 47.28%, #0BACA2 100.31%)">
                        <AleoTypography textStyle="body-small" weight="semibold" noMargin>
                            Welcome to Aleo. <MobileBreak />
                            <BlackText>Discover the world's first platform for private applications.</BlackText>
                        </AleoTypography>

                        <BannerButton href="">
                            <IconWrapper>
                                <AleoIcon icon="angle-right" fontSize={24} />
                            </IconWrapper>
                            <Underline>Join Now</Underline>
                        </BannerButton>
                    </RollupBanner>
                }
            >
                <div />
            </AleoHeader>

            <Layout.Content>
                <Title />

                <Section>
                    <RollupDesc
                        textStyle="heading-3"
                        mobileTextStyle="body"
                        weight="semibold"
                        style={{ maxWidth: 850, textAlign: 'center', margin: 'auto' }}
                    >
                        Aleo Rollup uses zero-knowledge cryptography to achieve fast, low-cost transfers of non-fungible
                        tokens (NFTs) and digital assets at real-world scale.
                    </RollupDesc>
                </Section>

                <Section>
                    <KeyGraphic />
                </Section>

                <Section>
                    <Row gutter={[0, 40]}>
                        <Col lg={12} xs={24}>
                            <RollupInfo />
                        </Col>

                        <Col lg={12} xs={24}>
                            <RollupSteps />
                        </Col>
                    </Row>
                </Section>

                <Section>
                    <ZeroReExecution />
                </Section>

                <Section>
                    <CryptographicIntegrity />
                </Section>

                <Section>
                    <UnlimitedRuntime />
                </Section>

                <Section>
                    <Newsletter />
                </Section>

                <Section style={{ marginTop: 100, marginBottom: 100 }}>
                    <Social />
                </Section>
            </Layout.Content>

            <AleoMiniFooter logo={logo} />
        </Layout>
    );
};

export default App;
