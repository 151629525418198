import { CustomIconProps } from '@aleohq/components/dist/components/Icons/types';
import Icon from '@ant-design/icons';

const Step3Svg = () => (
    <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="25.5" cy="25.5" r="25" fill="#12141D" stroke="url(#paint0_linear)" />
        <path
            d="M25.5025 35.4887C30.7203 35.4887 34.9502 31.1611 34.9502 25.8226C34.9502 20.4841 30.7203 16.1564 25.5025 16.1564C20.2846 16.1564 16.0547 20.4841 16.0547 25.8226C16.0547 31.1611 20.2846 35.4887 25.5025 35.4887Z"
            stroke="url(#paint1_linear)"
            stroke-width="0.54"
            stroke-miterlimit="10"
        />
        <path
            d="M25.4999 40.197C33.042 40.197 39.1561 37.5062 39.1561 34.1869C39.1561 30.8676 33.042 28.1767 25.4999 28.1767C17.9578 28.1767 11.8438 30.8676 11.8438 34.1869C11.8438 37.5062 17.9578 40.197 25.4999 40.197Z"
            stroke="url(#paint2_linear)"
            stroke-width="0.54"
            stroke-miterlimit="10"
        />
        <path
            d="M25.4999 23.466C33.042 23.466 39.1561 20.7751 39.1561 17.4558C39.1561 14.1365 33.042 11.4456 25.4999 11.4456C17.9578 11.4456 11.8438 14.1365 11.8438 17.4558C11.8438 20.7751 17.9578 23.466 25.4999 23.466Z"
            stroke="url(#paint3_linear)"
            stroke-width="0.54"
            stroke-miterlimit="10"
        />
        <path
            d="M13.969 23.5894C10.9026 22.0331 9 19.8593 9 17.4557C9 13.2093 14.9372 9.68426 22.731 9"
            stroke="url(#paint4_linear)"
            stroke-width="0.54"
            stroke-miterlimit="10"
        />
        <path
            d="M13.0742 23.6115L13.9748 23.5894L13.5305 22.9248"
            stroke="url(#paint5_linear)"
            stroke-width="0.54"
            stroke-miterlimit="10"
        />
        <path
            d="M37.031 28.0555C40.0974 29.6118 42 31.7857 42 34.1892C42 38.4356 36.058 41.9607 28.2617 42.6449"
            stroke="url(#paint6_linear)"
            stroke-width="0.54"
            stroke-miterlimit="10"
        />
        <path
            d="M37.9343 28.0334L37.0312 28.0531L37.4755 28.7177"
            stroke="url(#paint7_linear)"
            stroke-width="0.54"
            stroke-miterlimit="10"
        />
        <defs>
            <linearGradient
                id="paint0_linear"
                x1="0.430801"
                y1="39.2611"
                x2="51.3772"
                y2="38.7149"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#0BACA2" />
                <stop offset="1" stop-color="#6BEA65" />
            </linearGradient>
            <linearGradient
                id="paint1_linear"
                x1="16.8873"
                y1="35.1928"
                x2="35.7557"
                y2="33.5911"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#18E48F" />
                <stop offset="1" stop-color="#6BEA65" />
            </linearGradient>
            <linearGradient
                id="paint2_linear"
                x1="13.0473"
                y1="40.0131"
                x2="39.4872"
                y2="34.7954"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#18E48F" />
                <stop offset="1" stop-color="#6BEA65" />
            </linearGradient>
            <linearGradient
                id="paint3_linear"
                x1="13.0473"
                y1="23.282"
                x2="39.4872"
                y2="18.0643"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#18E48F" />
                <stop offset="1" stop-color="#6BEA65" />
            </linearGradient>
            <linearGradient
                id="paint4_linear"
                x1="9.60507"
                y1="23.3661"
                x2="23.3235"
                y2="22.2447"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#18E48F" />
                <stop offset="1" stop-color="#6BEA65" />
            </linearGradient>
            <linearGradient
                id="paint5_linear"
                x1="13.1139"
                y1="23.601"
                x2="14.0081"
                y2="23.4992"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#18E48F" />
                <stop offset="1" stop-color="#6BEA65" />
            </linearGradient>
            <linearGradient
                id="paint6_linear"
                x1="28.8671"
                y1="42.4216"
                x2="42.5926"
                y2="41.2991"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#18E48F" />
                <stop offset="1" stop-color="#6BEA65" />
            </linearGradient>
            <linearGradient
                id="paint7_linear"
                x1="37.071"
                y1="28.7072"
                x2="37.9675"
                y2="28.6044"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#18E48F" />
                <stop offset="1" stop-color="#6BEA65" />
            </linearGradient>
        </defs>
    </svg>
);

export const Step3Icon = (props: CustomIconProps) => <Icon component={Step3Svg} {...props} />;
