import { CustomIconProps } from '@aleohq/components/dist/components/Icons/types';
import Icon from '@ant-design/icons';

const GitHubSvg = () => (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.5017 1.28917e-06C6.93699 -0.00344145 0 6.88892 0 15.3959C0 22.123 4.33951 27.8414 10.383 29.9415C11.1968 30.1446 11.0722 29.5697 11.0722 29.1772V26.5091C6.37247 27.0565 6.18199 23.9649 5.86683 23.4485C5.22958 22.3675 3.72305 22.092 4.17328 21.5756C5.24344 21.0282 6.33438 21.7133 7.59848 23.569C8.51279 24.9151 10.2964 24.6879 11.2003 24.4641C11.3977 23.655 11.8202 22.9321 12.4021 22.3709C7.53268 21.5033 5.50318 18.5495 5.50318 15.0379C5.50318 13.3337 6.0677 11.7673 7.17596 10.5038C6.46945 8.42093 7.24176 6.6376 7.34566 6.3725C9.35784 6.19348 11.4497 7.80468 11.6124 7.93206C12.7553 7.62566 14.061 7.46385 15.5225 7.46385C16.991 7.46385 18.3001 7.63255 19.4534 7.94239C19.8447 7.64632 21.7842 6.26234 23.6543 6.43103C23.7548 6.69612 24.5098 8.43815 23.8448 10.4935C24.9669 11.7604 25.5384 13.3406 25.5384 15.0482C25.5384 18.5667 23.495 21.524 18.6118 22.3778C19.03 22.7867 19.3621 23.2743 19.5887 23.8122C19.8153 24.3501 19.9317 24.9275 19.9313 25.5107V29.3837C19.959 29.6936 19.9313 30 20.4508 30C26.5843 27.9447 31 22.185 31 15.3994C31 6.88891 24.0595 1.28917e-06 15.5017 1.28917e-06Z"
            fill="white"
        />
    </svg>
);

export const GitHubIcon = (props: CustomIconProps) => <Icon component={GitHubSvg} {...props} />;
