import { palette } from '@aleohq/ui/dist/palette';
import styled from 'styled-components';
import graphic from '../assets/graphic-3.png';
import { Col, Row } from 'antd';
import { AleoTypography } from '@aleohq/components';
import { DesktopBreak } from './common';

const Card = styled.div`
    border: 1px solid ${palette.gray6};
    border-radius: 20px;
    background: url(${graphic}) no-repeat center;
    background-size: 390px 430px;
    height: 560px;

    @media (max-width: 600px) {
        height: 345px;
        background-size: 250px 275px;
    }

    @media (max-width: 800px) {
        height: 395px;
        background-size: 275px 300px;
    }
`;

const RightAlignCol = styled(Col)`
    display: flex;
    justify-content: flex-end;
`;

const Content = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-self: flex-end;
    max-width: 420px;

    @media (max-width: 600px) {
        text-align: center;
    }

    @media (max-width: 800px) {
        max-width: 310px;
    }
`;

const UnlimitedRuntime = () => (
    <Row gutter={[20, 40]}>
        <Col md={12} xs={24}>
            <Card />
        </Col>
        <RightAlignCol md={12} xs={24}>
            <Content>
                <AleoTypography textStyle="heading-1" mobileTextStyle="heading-3" weight="semibold">
                    Unlimited <DesktopBreak />
                    Runtime
                </AleoTypography>

                <AleoTypography textStyle="body" type="secondary">
                    Aleo Rollup enables developers to write sophisticated application logic for NFT transfers, achieving
                    rich expressivity and flexibility that is unachievable on existing smart contract platforms.
                    <br />
                    <br />
                    With verifiable computation, developers can write applications that are Turing-decidable and execute
                    off-chain without restrictions from miners or gas limits.
                </AleoTypography>
            </Content>
        </RightAlignCol>
    </Row>
);

export default UnlimitedRuntime;
