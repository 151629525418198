import { palette } from '@aleohq/ui/dist/palette';
import styled from 'styled-components';
import graphic from '../assets/graphic-2.png';
import { Col, Row } from 'antd';
import { AleoTypography } from '@aleohq/components';

const Card = styled.div`
    border: 1px solid ${palette.gray6};
    border-radius: 20px;
    background: url(${graphic}) no-repeat center;
    background-size: 390px 430px;
    height: 560px;

    @media (max-width: 600px) {
        height: 345px;
        background-size: 250px 275px;
    }

    @media (max-width: 800px) {
        height: 395px;
        background-size: 275px 300px;
    }
`;

const Content = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    max-width: 450px;

    @media (max-width: 600px) {
        text-align: center;
    }

    @media (max-width: 800px) {
        max-width: 310px;
    }
`;

const ReverseRow = styled(Row)`
    @media (max-width: 600px) {
        flex-direction: column-reverse;
    }
`;

const CryptographicIntegrity = () => (
    <ReverseRow gutter={[20, 40]}>
        <Col md={12} xs={24}>
            <Content>
                <AleoTypography textStyle="heading-1" mobileTextStyle="heading-3" weight="semibold">
                    Cryptographic Integrity
                </AleoTypography>

                <AleoTypography textStyle="body" type="secondary">
                    Zero-knowledge proofs provide a cryptographic guarantee about the integrity of computations,
                    allowing users to safely broadcast NFT transfers to Aleo Rollup operators for processing, without
                    compromising on trust.
                    <br />
                    <br />
                    In addition, Aleo Rollup extends zero-knowledge execution models to support a wide class of digital
                    assets and business logic, wherever real-world scale is required.
                </AleoTypography>
            </Content>
        </Col>
        <Col md={12} xs={24}>
            <Card />
        </Col>
    </ReverseRow>
);

export default CryptographicIntegrity;
