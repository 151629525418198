import styled from 'styled-components';
import { palette } from '@aleohq/ui/dist/palette';
import { Steps } from 'antd';
import { Step1Icon } from './icons/Step1';
import { Step2Icon } from './icons/Step2';
import { Step3Icon } from './icons/Step3';
import { Step4Icon } from './icons/Step4';

const StyledSteps = styled(Steps)`
    max-width: 500px;
    margin: auto;
    position: relative;
    left: -25px;

    @media (max-width: 800px) {
        position: static;
        left: unset;
    }

    .ant-steps-item {
        @media (max-width: 800px) {
            &:first-child {
                margin-top: 80px;

                &:before {
                    height: 80px;
                    content: '';
                    border-left: 1px solid transparent;
                    border-right: 1px solid transparent;

                    border-bottom: 80px solid #6bea65;

                    position: absolute;
                    left: calc(50% + 2px);
                    transform: translateX(-50%);
                    top: -80px;
                }
            }
        }

        .ant-steps-item-container {
            @media (max-width: 800px) {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: auto;
                text-align: center;
                max-width: 275px;
            }
        }
    }

    .ant-steps-item:not(:last-child) {
        padding-bottom: 70px;

        @media (max-width: 800px) {
            padding-bottom: 40px;
        }
    }

    .ant-steps-item .ant-steps-item-icon {
        width: 50px;
        height: 50px;
        margin-right: 40px;

        @media (max-width: 800px) {
            margin: 0;
        }
    }

    &.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
        left: 25px;
        padding: 38px 0 0;

        @media (max-width: 800px) {
            display: none;
        }
    }

    .ant-steps-item-active .ant-steps-item-icon {
        background: none;
        border: none;
    }

    .ant-steps-item-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 120%;
        color: #ffffff !important;
        padding-top: 7px;

        @media (max-width: 800px) {
            padding: 0;
        }
    }

    .ant-steps-item-description {
        margin-top: 10px;
        font-size: 16px;
        line-height: 120%;
        color: ${palette.gray9} !important;
    }
`;

const RollupSteps = () => {
    return (
        <StyledSteps direction="vertical">
            <Steps.Step
                status="finish"
                title="Step 1"
                description="Users sign and broadcast NFT transfers to an Aleo Rollup operator, who receives the transfer and adds it to a queue of pending transfers."
                icon={<Step1Icon />}
            />
            <Steps.Step
                status="finish"
                title="Step 2"
                description="Once the pending queue reaches capacity, an Aleo Rollup operator aggregates the NFT transfers and generates a zero-knowledge proof attesting to the validity of each transfer and its corresponding state update."
                icon={<Step2Icon />}
            />
            <Steps.Step
                status="finish"
                title="Step 3"
                description="The Aleo Rollup operator crafts a transaction encapsulating a state transition about the NFT transfers from the queue, along with a zero-knowledge proof attesting to the computational integrity of each transfer, and broadcasts the transaction to the Aleo network."
                icon={<Step3Icon />}
            />
            <Steps.Step
                status="finish"
                title="Step 4"
                description="The Aleo network receives this transaction, verifies the zero-knowledge proof, and confirms the transaction in a new block within 20 seconds. The state of this rollup is  verifiably encrypted under the address of the Aleo Rollup operator and finalized in a record on-chain."
                icon={<Step4Icon />}
            />
        </StyledSteps>
    );
};

export default RollupSteps;
