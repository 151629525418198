import { CustomIconProps } from '@aleohq/components/dist/components/Icons/types';
import Icon from '@ant-design/icons';

const Step4Svg = () => (
    <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="25.5" cy="25.5" r="25" fill="#12141D" stroke="url(#paint0_linear)" />
        <path d="M26 16L26 8" stroke="url(#paint1_linear)" stroke-width="0.5" stroke-miterlimit="10" />
        <path d="M26 36.9999V44.9999" stroke="url(#paint2_linear)" stroke-width="0.5" stroke-miterlimit="10" />
        <path
            d="M25 37.9999L26 36.9999L27 37.9999"
            stroke="url(#paint3_linear)"
            stroke-width="0.5"
            stroke-miterlimit="10"
        />
        <path d="M27 15L26 16L25 15" stroke="url(#paint4_linear)" stroke-width="0.5" stroke-miterlimit="10" />
        <path
            d="M25.4159 27C25.6887 27 25.9098 26.7761 25.9098 26.5C25.9098 26.2238 25.6887 26 25.4159 26C25.143 26 24.9219 26.2238 24.9219 26.5C24.9219 26.7761 25.143 27 25.4159 27Z"
            fill="#12141D"
            stroke="url(#paint5_linear)"
            stroke-width="0.2"
            stroke-miterlimit="10"
        />
        <path
            d="M25.4131 31C37.1443 31 46.6543 28.9852 46.6543 26.5C46.6543 24.0147 37.1443 22 25.4131 22C13.6819 22 4.17188 24.0147 4.17188 26.5C4.17188 28.9852 13.6819 31 25.4131 31Z"
            stroke="url(#paint6_linear)"
            stroke-width="0.5"
            stroke-miterlimit="10"
        />
        <path
            d="M45.3755 33.7854C46.2727 31.2903 38.1014 25.9894 27.1245 21.9454C16.1476 17.9015 6.52173 16.6459 5.62453 19.1409C4.72733 21.636 12.8986 26.9369 23.8755 30.9809C34.8524 35.0248 44.4783 36.2804 45.3755 33.7854Z"
            stroke="url(#paint7_linear)"
            stroke-width="0.5"
            stroke-miterlimit="10"
        />
        <path
            d="M27.1236 31.0366C38.1 26.9928 46.2707 21.692 45.3735 19.197C44.4763 16.7019 34.8509 17.9573 23.8745 22.0011C12.8981 26.0449 4.72733 31.3456 5.62453 33.8407C6.52173 36.3358 16.1472 35.0803 27.1236 31.0366Z"
            stroke="url(#paint8_linear)"
            stroke-width="0.5"
            stroke-miterlimit="10"
        />
        <defs>
            <linearGradient
                id="paint0_linear"
                x1="0.430801"
                y1="39.2611"
                x2="51.3772"
                y2="38.7149"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#0BACA2" />
                <stop offset="1" stopColor="#6BEA65" />
            </linearGradient>
            <linearGradient
                id="paint1_linear"
                x1="25.9559"
                y1="8.12243"
                x2="24.9503"
                y2="8.13335"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#18E48F" />
                <stop offset="1" stopColor="#6BEA65" />
            </linearGradient>
            <linearGradient
                id="paint2_linear"
                x1="26.0441"
                y1="44.8774"
                x2="27.0497"
                y2="44.8665"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#18E48F" />
                <stop offset="1" stopColor="#6BEA65" />
            </linearGradient>
            <linearGradient
                id="paint3_linear"
                x1="26.9119"
                y1="37.0152"
                x2="24.9593"
                y2="37.3544"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#18E48F" />
                <stop offset="1" stopColor="#6BEA65" />
            </linearGradient>
            <linearGradient
                id="paint4_linear"
                x1="25.0881"
                y1="15.9847"
                x2="27.0407"
                y2="15.6455"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#18E48F" />
                <stop offset="1" stopColor="#6BEA65" />
            </linearGradient>
            <linearGradient
                id="paint5_linear"
                x1="24.9654"
                y1="26.9847"
                x2="25.9518"
                y2="26.9"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#18E48F" />
                <stop offset="1" stopColor="#6BEA65" />
            </linearGradient>
            <linearGradient
                id="paint6_linear"
                x1="6.04391"
                y1="30.8622"
                x2="42.6231"
                y2="15.8662"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#18E48F" />
                <stop offset="1" stopColor="#6BEA65" />
            </linearGradient>
            <linearGradient
                id="paint7_linear"
                x1="43.2858"
                y1="37.6808"
                x2="46.4449"
                y2="28.5558"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#18E48F" />
                <stop offset="1" stopColor="#6BEA65" />
            </linearGradient>
            <linearGradient
                id="paint8_linear"
                x1="8.95092"
                y1="37.5749"
                x2="39.1452"
                y2="11.5123"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#18E48F" />
                <stop offset="1" stopColor="#6BEA65" />
            </linearGradient>
        </defs>
    </svg>
);

export const Step4Icon = (props: CustomIconProps) => <Icon component={Step4Svg} {...props} />;
