import { AleoTypography, MaskText } from '@aleohq/components';
import React from 'react';
import styled from 'styled-components';

const gradient = 'linear-gradient(89.39deg, #0BACA2 1.08%, #6BEA65 99.93%)';

const RollupInfoWrapper = styled.div`
    @media (max-width: 800px) {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

const RollupBody = styled(AleoTypography)`
    max-width: 425px;
    margin-bottom: 70px;

    @media (max-width: 600px) {
        max-width: 275px;
    }
`;

const RollupInfo = () => (
    <RollupInfoWrapper>
        <AleoTypography
            textStyle="heading-1"
            mobileTextStyle="heading-3"
            weight="semibold"
            style={{ maxWidth: 300, marginBottom: 40 }}
        >
            The Next Generation of zkRollups
        </AleoTypography>

        <RollupBody textStyle="subtitle" mobileTextStyle="body" weight="semibold">
            Aleo Rollup uses zero-knowledge proofs to aggregate and process non-fungible token (NFT) transfers at
            real-world scale.
            <br />
            <br />
            Users of Aleo Rollup achieve fast, low-cost NFT transfers without compromising on security.
        </RollupBody>

        <MaskText mask={gradient}>
            <AleoTypography textStyle="title" weight="semibold" noMargin>
                100x
            </AleoTypography>
        </MaskText>

        <AleoTypography textStyle="subtitle" weight="semibold" style={{ marginBottom: 40 }}>
            Transaction Throughput
        </AleoTypography>

        <MaskText mask={gradient}>
            <AleoTypography textStyle="title" weight="semibold" noMargin>
                0
            </AleoTypography>
        </MaskText>

        <AleoTypography textStyle="subtitle" weight="semibold" style={{ marginBottom: 40 }}>
            Gas Fees
        </AleoTypography>

        <MaskText mask={gradient}>
            <AleoTypography textStyle="title" weight="semibold" noMargin>
                1 KB
            </AleoTypography>
        </MaskText>

        <AleoTypography textStyle="subtitle" weight="semibold">
            Transaction Sizes
        </AleoTypography>
    </RollupInfoWrapper>
);

export default RollupInfo;
