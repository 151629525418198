import { palette } from '@aleohq/ui/dist/palette';
import styled from 'styled-components';
import graphic from '../assets/graphic-1.png';
import mobileGraphic from '../assets/graphic-1-mobile.png';
import tabletGraphic from '../assets/graphic-1-tablet.png';
import { AleoTypography } from '@aleohq/components';
import { DesktopBreak } from './common';

const Card = styled.div`
    border: 1px solid ${palette.gray6};
    border-radius: 20px;
    background: url(${graphic}) no-repeat;
    background-size: contain;
    padding: 90px 128px;
    height: 560px;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 600px) {
        flex-direction: column;
        background: url(${mobileGraphic}) no-repeat top;
        background-size: 100%;
        height: 800px;
    }

    @media (max-width: 800px) {
        background: url(${tabletGraphic}) no-repeat;
        background-size: contain;
        height: 425px;
        padding: 50px 30px;
    }
`;

const Content = styled.div`
    width: 50%;
    max-width: 350px;

    @media (max-width: 600px) {
        width: 100%;
        text-align: center;
    }

    @media (max-width: 800px) {
        max-width: 310px;
    }
`;

const ZeroReExecution = () => (
    <Card>
        <Content>
            <AleoTypography textStyle="heading-1" mobileTextStyle="heading-3" weight="semibold">
                Zero <DesktopBreak />
                Re-Execution
            </AleoTypography>

            <AleoTypography textStyle="body" type="secondary">
                Aleo Rollup creates a zero-knowledge proof attesting to the validity of every NFT transfer and
                corresponding state update.
                <br />
                <br />
                With zero-knowledge proofs, Aleo Rollup transactions are verifiably computed, and thus, never require
                re-execution by miners, operators, or users. This enables Aleo Rollup to achieve throughput for NFT
                transfers that scales for real-world systems.
            </AleoTypography>
        </Content>
    </Card>
);

export default ZeroReExecution;
