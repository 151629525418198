import { AleoTypography } from '@aleohq/components';
import { Button, Form, Grid, Input, notification } from 'antd';

import { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

interface SubscribeForm {
    name: string;
    email: string;
}

const NewsletterWrapper = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
`;

const Link = styled.a`
    color: #ffffff;
    text-decoration: none;
`;

const NewsletterBody = styled(AleoTypography)`
    max-width: 650px;
    margin: auto;

    @media (max-width: 600px) {
        max-width: 275px;
    }
`;

const Newsletter = () => {
    const [subscribeLoading, setSubscribeLoading] = useState(false);
    const [form] = Form.useForm();
    const breakpoint = Grid.useBreakpoint();

    const onSubscribe = async (data: SubscribeForm) => {
        setSubscribeLoading(true);

        try {
            await axios.post('https://sg.aleo.org/.netlify/functions/subscribe', {
                ...data,
                newsletterList: 'ALEO_LIST'
            });

            notification.success({
                message: 'Thank you for signing up for our mailing list!'
            });
        } catch (e) {
            switch (e.response.data) {
                case 'BAD_NAME':
                    notification.error({
                        message: 'Please provide a valid name!'
                    });
                    break;
                case 'SENDGRID_ERROR':
                case 'BAD_APIKEY':
                    notification.error({
                        message: 'The server is not set up correctly. Please contact Aleo!'
                    });
                    break;
                case 'BAD_NEWSLETTER':
                    notification.error({
                        message: 'The newsletter you tried to sign up for does not exist. Please contact Aleo!'
                    });
                    break;
                default:
                    notification.error({
                        message: e.response.statusText
                    });
            }
        } finally {
            setSubscribeLoading(false);
        }
    };

    return (
        <NewsletterWrapper>
            <AleoTypography textStyle="heading-1" mobileTextStyle="heading-3" weight="semibold">
                Join the conversation
            </AleoTypography>

            <NewsletterBody textStyle="subtitle" mobileTextStyle="body" weight="semibold">
                Get started with Aleo now. Be the first to see what we are building for you and help us bring zero
                knowledge technology to the web.
            </NewsletterBody>

            <Form
                layout={breakpoint.xs ? 'vertical' : 'inline'}
                style={{ margin: 'auto', marginTop: 40, marginBottom: 20 }}
                onFinish={onSubscribe}
                form={form}
            >
                <Form.Item name="name" rules={[{ required: true, message: 'Name is required!' }]}>
                    <Input placeholder="Name" size="large" style={{ minWidth: 320 }} />
                </Form.Item>
                <Form.Item
                    name="email"
                    rules={[
                        { required: true, message: 'Email is required!' },
                        { type: 'email', message: 'Must be a valid email!' }
                    ]}
                    style={{ marginRight: 0 }}
                >
                    <Input placeholder="Email Address" size="large" style={{ minWidth: 320 }} />
                </Form.Item>
            </Form>

            <AleoTypography textStyle="body-small" type="secondary">
                By signing up you agree to our <Link href="">Terms & Conditions</Link>
            </AleoTypography>

            <div>
                <Button
                    type="primary"
                    size="large"
                    style={{ marginTop: 40 }}
                    onClick={form.submit}
                    loading={subscribeLoading}
                >
                    Subscribe
                </Button>
            </div>
        </NewsletterWrapper>
    );
};

export default Newsletter;
