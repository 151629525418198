import styled from 'styled-components';
import titleGraphic from '../assets/title-graphic.svg';
import { AleoTypography } from '@aleohq/components';
import aleoLogo from '../assets/aleo.png';

const TitleWrapper = styled.div`
    background-image: url(${titleGraphic});
    background-repeat: no-repeat;
    background-size: contain;
    height: 735px;
    max-width: 1110px;
    margin: 80px auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    @media (max-width: 800px) {
        background: none;
        height: 250px;
        margin-top: 150px;
    }
`;

const TitleText = styled(AleoTypography)`
    font-size: 96px;
    line-height: 120%;
    letter-spacing: 0.1em;
    margin-right: -0.1em;
    margin-bottom: 30px;

    @media (max-width: 600px) {
        font-size: 64px;
    }
`;

const SubtitleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
`;

const HeadingText = styled(AleoTypography)`
    @media (max-width: 800px) and (min-width: 600px) {
        font-size: 24px !important;
    }
`;

const SubtitleText = styled(AleoTypography)`
    font-size: 18px;
    line-height: 120%;
    letter-spacing: 0.2em;

    @media (max-width: 600px) {
        font-size: 14px;
    }
`;

const AleoLogo = styled.img`
    height: 30px;
    width: auto;

    @media (max-width: 600px) {
        height: 23px;
    }
`;

const Title = () => {
    return (
        <TitleWrapper>
            <HeadingText
                textStyle="heading-4"
                mobileTextStyle="body"
                style={{ letterSpacing: '0.4em', marginRight: '-0.4em' }}
            >
                ZERO-KNOWLEDGE
            </HeadingText>
            <TitleText textStyle="title" weight="semibold">
                ROLLUPS
            </TitleText>
            <SubtitleWrapper>
                <SubtitleText textStyle="subtitle" noMargin>
                    POWERED BY
                </SubtitleText>

                <AleoLogo src={aleoLogo} />
            </SubtitleWrapper>
        </TitleWrapper>
    );
};

export default Title;
