import { Card, Col, Row } from 'antd';
import { GitHubIcon } from './icons/GitHubIcon';
import { AleoTypography } from '@aleohq/components';
import { TwitterIcon } from './icons/TwitterIcon';
import { DiscordIcon } from './icons/DiscordIcon';
import styled from 'styled-components';

const TransparentCard = styled(Card)`
    background: transparent;
    border: none;

    .ant-card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

const IconLink = styled.a`
    text-align: center;
`;

const Social = () => (
    <Row gutter={[20, 20]}>
        <Col md={8} xs={24}>
            <TransparentCard style={{ gap: 0 }}>
                <IconLink href="https://github.com/AleoHQ/leo" target="_blank" rel="noreferrer">
                    <GitHubIcon />

                    <AleoTypography textStyle="subtitle" weight="semibold" noMargin style={{ marginTop: 15 }}>
                        GitHub
                    </AleoTypography>
                    <AleoTypography textStyle="body-small" type="secondary">
                        Contribute to the codebase
                    </AleoTypography>
                </IconLink>
            </TransparentCard>
        </Col>
        <Col md={8} xs={24}>
            <TransparentCard style={{ gap: 0 }}>
                <IconLink href="https://twitter.com/AleoHQ" target="_blank" rel="noreferrer">
                    <TwitterIcon />

                    <AleoTypography textStyle="subtitle" weight="semibold" noMargin style={{ marginTop: 15 }}>
                        Twitter
                    </AleoTypography>
                    <AleoTypography textStyle="body-small" type="secondary">
                        Follow @aleohq
                    </AleoTypography>
                </IconLink>
            </TransparentCard>
        </Col>
        <Col md={8} xs={24}>
            <TransparentCard style={{ gap: 0 }}>
                <IconLink href="https://discord.gg/SMXsDEQ" target="_blank" rel="noreferrer">
                    <DiscordIcon />

                    <AleoTypography textStyle="subtitle" weight="semibold" noMargin style={{ marginTop: 15 }}>
                        Discord
                    </AleoTypography>
                    <AleoTypography textStyle="body-small" type="secondary">
                        Ask questions
                    </AleoTypography>
                </IconLink>
            </TransparentCard>
        </Col>
    </Row>
);

export default Social;
